import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17')
];

export const server_loads = [0,2];

export const dictionary = {
		"/(core)": [~4,[2]],
		"/api/v1/callback/google": [~6],
		"/auth/google": [~7,[3]],
		"/auth/intermidiate": [~8,[3]],
		"/auth/login": [~9,[3]],
		"/auth/logout": [~10,[3]],
		"/auth/register": [~11,[3]],
		"/auth/register/invite/[roomId]": [~12,[3]],
		"/auth/register/room": [~13,[3]],
		"/stats": [~15],
		"/s/[origin]": [~14],
		"/template/[templateId]": [~16],
		"/template/[templateId]/preview": [~17],
		"/(core)/[roomId]": [~5,[2]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';